import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import FeatherIcon from "feather-icons-react";

import { Button } from "components/buttons/buttons";
import { Popconfirm } from "antd";
import { hasPermission } from "utility/accessibility/hasPermission";

export interface DestinationType {
  id?: string;
  name?: string;
  flag?:string;
}

export interface DestinationProps {
  onSave?: (destination: DestinationType) => void;
  onHide?: () => void;
  isVisible?: boolean;
  companyId: string;
}

interface ActionHandlers {
  handleDeleteClick: (record: DestinationType) => void;
}

export const useDestinationColumns = ({
  handleDeleteClick,
}: ActionHandlers): ColumnsType<DestinationType> => {
  const { t } = useTranslation();

  return [
    {
      title: t("destinations.table.country", "Country"),
      dataIndex: "name",
      key: "name",
      sorter: false,
      render: (name, record) => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {record.flag && (
            <img
              src={record.flag}
              alt={`${name} flag`}
              style={{
                width: "20px",
                height: "15px",
                objectFit: "cover",
              }}
            />
          )}
          <span>{name}</span>
        </div>
      ),
    },
    {
      title: t("destinations.table.action", "Action"),
      dataIndex: "action",
      key: "action",
      sorter: false,
      render: (_, record) => (
        <div className="table-actions" style={{ clear: "both" }}>
          {hasPermission("users:delete") ? (
            <Popconfirm
              title={t(
                "destinations:alert.delete-confirm",
                "This step is irreversible, are you sure you want to delete {{dynamicValue}}?",
                { dynamicValue: `${record.name}` }
              )}
              onConfirm={() => handleDeleteClick(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="btn-icon" type="info" shape="circle">
                <FeatherIcon icon="trash" size={25} />
              </Button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];
};
