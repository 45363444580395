import { Button, Col, Form, Input, Row, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Cards } from "components/cards/frame/cards-frame";
import { PageHeader } from "components/page-headers/page-headers";
import {
  BasicFormWrapper,
  CardToolbox,
  Main,
  TableWrapper,
} from "container/styled";
import { useAuthorization } from "hooks/useAuthorization";
import openNotificationWithIcon from "utility/notification";
import { EmailOptionsApi } from "../../../api";
import { Modal } from "../../../components/modals/antd-modals";
import { UserTableStyleWrapper } from "../../style";

const emailOptionsApi = new EmailOptionsApi();

function hasErrors(fieldErrors) {
  return fieldErrors.some(({ errors }) => errors.length > 0);
}

const EmailPage = () => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();

  const [tableColumns] = useState([
    {
      title: t("configure.email.from", "Email (From)"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("configure.email.smtpHost", "SMTP Host"),
      dataIndex: "smtpHost",
      key: "smtpHost",
    },
    {
      title: t("configure.email.smtpPass", "SMTP Password"),
      dataIndex: "smtpPass",
      key: "smtpPass",
    },
    {
      title: t("configure.email.smtpUser", "SMTP User Account"),
      dataIndex: "smtpUser",
      key: "smtpUser",
    },
    {
      title: "",
    },
  ]);

  const [emailData, setEmailData] = useState([]);
  const [form] = Form.useForm();

  const [addEmailConfiguration, setAddEmailConfiguration] = useState({
    isLoading: false,
    modalVisible: false,
    testedConnections: false,
    testConnectionsError: false,
    testConnectionsLoading: false,
  });

  const emailTableData = emailData.map((emailObj) => ({
    from: emailObj.from,
    smtpHost: emailObj.smtpHost,
    smtpPass: "************",
    smtpUser: emailObj.smtpUser,
  }));

  const handleSubmit = async (values) => {
    try {
      if (!addEmailConfiguration.testedConnections) {
        handleTestConnection();
        return;
      }

      setAddEmailConfiguration((p) => ({ ...p, isLoading: true }));
      await emailOptionsApi.emailOptionsPost({
        createNewEmailOptionsCommand: values,
      });

      openNotificationWithIcon(
        "success",
        t(
          "email.options.success.add",
          "Email Configuration was created successfully"
        )
      );

      setAddEmailConfiguration((p) => ({
        ...p,
        isLoading: false,
        modalVisible: false,
      }));

      getEmailOptions();
    } catch (error) {
      console.error("Error submitting email configuration:", error);
      openNotificationWithIcon(
        "error",
        "Failed to submit email configuration."
      );
    }
  };

  const handleTestConnection = async () => {
    try {
      const errors = form.getFieldsError();
      if (hasErrors(errors)) {
        const errorMessages = errors
          .filter(({ errors }) => errors.length > 0)
          .map(({ name, errors }) => `${name.join(", ")}: ${errors[0]}`);

        openNotificationWithIcon(
          "error",
          `Please fix the following errors: ${errorMessages.join(", ")}`
        );
        return;
      }

      setAddEmailConfiguration((p) => ({
        ...p,
        testConnectionsError: false,
        testConnectionsLoading: true,
      }));

      const values = form.getFieldsValue();

      const response = await emailOptionsApi.emailOptionsTestConnectionPost({
        testSmtpConnectionCommand: {
          email: values.from,
          smtpServer: values.smtpHost,
          port: parseInt(values.smtpPort, 10),
          username: values.smtpUser,
          password: values.smtpPass,
        },
      });
      var success = false;
      if (response.data === "SMTP connection test passed") {
        success = true;
      }
      // Update state based on the message
      setAddEmailConfiguration((p) => ({
        ...p,
        testedConnections: success,
        testConnectionsLoading: false,
      }));
    } catch (error) {
      // console.error(
      //   "Error testing connection:",
      //   error.response ? error.response.data : error
      // );
      debugger;
      // Log the entire error response for debugging
      // console.log("Error response:", error.response);

      setAddEmailConfiguration((p) => ({
        ...p,
        testConnectionsError: true,
        testConnectionsLoading: false,
        testConnectionsErrorMessage:
          error.response &&
          error.response.data &&
          typeof error.response.data === "string"
            ? error.response.data
            : "An error occurred", // Ensure this is a string
      }));
      // openNotificationWithIcon(
      //   "error",
      //   "An error occurred while testing the connection."
      // );
    }
  };

  const getEmailOptions = async () => {
    setAddEmailConfiguration((p) => ({ ...p, isLoading: true }));
    try {
      const { data } = await emailOptionsApi.emailOptionsActiveGet();
      setEmailData(data ? [data] : []);
    } catch (error) {
      console.error("Error fetching email options:", error);
    } finally {
      setAddEmailConfiguration((p) => ({ ...p, isLoading: false }));
    }
  };

  useEffect(() => {
    getEmailOptions();
  }, []);

  // console.log("Test Connection Error State:", addEmailConfiguration);

  return (
    <div>
      <CardToolbox>
        <PageHeader
          key={"email-page-header"}
          ghost
          title={t("email-options.title", "SMTP Email Config")}
          buttons={[
            hasPermission("users:add") && (
              <Button
                onClick={() =>
                  setAddEmailConfiguration((p) => ({
                    ...p,
                    modalVisible: true,
                  }))
                }
                className="btn-add_new"
                size="default"
                type="primary"
                key="add-email-config-modal"
              >
                {t("email-configurations.update", "Update Email Configuration")}
              </Button>
            ),
          ]}
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col sm={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <TableWrapper>
                  <Table
                    style={{ textAlign: "left" }}
                    pagination={false}
                    loading={addEmailConfiguration.isLoading}
                    dataSource={
                      Array.isArray(emailTableData) ? emailTableData : []
                    }
                    columns={tableColumns}
                    rowKey={(record) => record.from} // Use a unique identifier
                    scroll={{ x: "max-content" }}
                  />
                </TableWrapper>
              </UserTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
      </Main>

      <Modal
        type="primary"
        title={t("email:title", "Update Email Configuration")}
        footer={null}
        visible={addEmailConfiguration.modalVisible}
        onCancel={() =>
          setAddEmailConfiguration((p) => ({ ...p, modalVisible: false }))
        }
      >
        {addEmailConfiguration.isLoading ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: 320,
            }}
          />
        ) : (
          <BasicFormWrapper>
            <Form
              id="addEmailConfig"
              form={form}
              name="email"
              onFinish={handleSubmit}
              requiredMark
            >
              <Form.Item
                name="from"
                rules={[
                  {
                    required: true,
                    message: t("errors.send-from-email", "Send From Email!"),
                    type: "email",
                  },
                ]}
              >
                <Input type="email" placeholder="Email" required />
              </Form.Item>
              <Form.Item
                name="smtpHost"
                rules={[
                  {
                    required: true,
                    message: t(
                      "errors.smtp-host-required",
                      "SMTP Host is required"
                    ),
                  },
                ]}
              >
                <Input placeholder="SMTP Host ex: smtp.gmail.com" required />
              </Form.Item>
              <Form.Item
                name="smtpPort"
                rules={[
                  {
                    required: true,
                    message: t(
                      "errors.smtp-port-required",
                      "SMTP Port is required"
                    ),
                  },
                ]}
              >
                <Input type="number" placeholder="SMTP Port" required />
              </Form.Item>
              <Form.Item
                name="smtpUser"
                rules={[
                  {
                    required: true,
                    message: t(
                      "errors.smtp-user-required",
                      "SMTP User is required"
                    ),
                  },
                ]}
              >
                <Input placeholder="SMTP Account User" required />
              </Form.Item>
              <Form.Item
                name="smtpPass"
                rules={[
                  {
                    required: true,
                    message: t(
                      "errors.smtp-password-required",
                      "SMTP Password is required"
                    ),
                  },
                ]}
              >
                <Input placeholder="SMTP Password" type="password" required />
              </Form.Item>
              <div
                style={{
                  paddingTop: 20,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  {addEmailConfiguration.testedConnections && (
                    <div style={{ color: "#4347d9" }}>
                      {t(
                        "email-options.email-test-connection-success",
                        "Connection was successful!"
                      )}
                    </div>
                  )}
                  {addEmailConfiguration.testConnectionsError && (
                    <div style={{ color: "red" }}>
                      {addEmailConfiguration.testConnectionsErrorMessage ||
                        t(
                          "errors.email-test-connection-failed",
                          "Connection with the server failed, please check your credentials!"
                        )}
                    </div>
                  )}
                </div>
                <div>
                  {!addEmailConfiguration.testedConnections ? (
                    <Button
                      htmlType="submit"
                      size="default"
                      type="light"
                      loading={addEmailConfiguration.testConnectionsLoading}
                      disabled={addEmailConfiguration.testConnectionsLoading}
                    >
                      {t("email.test-connection", "Test Connection")}
                    </Button>
                  ) : null}

                  {addEmailConfiguration.testedConnections && (
                    <Button
                      form="addEmailConfig"
                      htmlType="submit"
                      size="default"
                      type="primary"
                    >
                      {t("email.save-as-default", "Save as default")}
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </BasicFormWrapper>
        )}
      </Modal>
    </div>
  );
};

export default EmailPage;
