import { useModalState } from "hooks/useModalState";
import React from "react";
import { useTranslation } from "react-i18next";
import { hasPermission } from "utility/accessibility/hasPermission";
import openNotificationWithIcon from "utility/notification.js";
import { Table } from "antd";
import { TableWrapper } from "container/styled";
import { Cards } from "components/cards/frame/cards-frame.js";
import FeatherIcon from "feather-icons-react";
import { Button } from "components/buttons/buttons";
import {
  NonWorkingDaysProps,
  useNonWorkingDaysColumns,
} from "./companyNonWorkingDaysDataTableColumns";
import { CompaniesApi } from "api/api";
import NonWorkingDays from "./nonWorkingDays";
import { useNonWorkingDaysFetch } from "./Hooks/useComaniesNonWorkingDaysFetch";

const companiesApi = new CompaniesApi();

const CompanyNonWorkingDays = ({ companyId }: NonWorkingDaysProps) => {
  const { t } = useTranslation();
  const { data, isLoading, fetchNoWorkingDays, setQuery } =
    useNonWorkingDaysFetch({companyId});

  const canAddDate = hasPermission("users:add");

  const {
    modalState,
    closeModal,
    handleAddClick,
    handleViewClick,
    handleEditClick,
  } = useModalState(fetchNoWorkingDays);

  const columns = useNonWorkingDaysColumns({
    handleViewClick,
    handleEditClick,
    handleDeleteClick: async (item: any) => {
      try {
        await companiesApi.apiCompaniesIdNonWorkingDaysDelete({ id: item.id });

        openNotificationWithIcon(
          "success",
          t(
            "non-working-days-Modal:date-deleted-success",
            "Date deleted successfully!"
          )
        );
        fetchNoWorkingDays();
      } catch (error) {}
    },
  });

  return (
    <div style={{ width: "100%" }}>
      <Cards
        title={t("non-working-days:title", "Non working days")}
        size="large"
        isbutton={
          canAddDate && (
            <Button
              onClick={handleAddClick}
              className="btn-add_new"
              size="default"
              type="white"
              key="add-codebook"
            >
              <FeatherIcon icon="plus" size={25} />
              {t("non-working-days:button.add", "Add New")}
            </Button>
          )
        }
      >
        <TableWrapper className="table-responsive">
          <Table
            loading={isLoading}
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            pagination={{
              current: data?.pageIndex,
              total: data?.totalCount,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100", "1000"],
              onChange: (pageNumber, pageSize) =>
                setQuery((prev) => ({ ...prev, pageNumber, pageSize })),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </Cards>
      <NonWorkingDays
        isVisible={modalState.addModalVisible}
        onHide={closeModal}
        mode={modalState.mode as any}
        dateToEdit={modalState.itemForEditModal}
        onSave={fetchNoWorkingDays}
        companyId={companyId}
      />
    </div>
  );
};

export default CompanyNonWorkingDays;
