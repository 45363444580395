import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Row,
  Col,
  Spin,
} from "antd";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import { UploadOutlined } from "@ant-design/icons";

import { Modal } from "components/modals/antd-modals";
import { EquipmentProps } from "components/equipments/equipmentDataTableColumns";

import { EquipmentApi, CodebookApi } from "api/api";
import openNotificationWithIcon from "utility/notification";
import { ModalMode } from "hooks/useModalState";
import Dragger from "antd/lib/upload/Dragger";
import { base64ToFile, downloadFilesFromBase64 } from "utility/dowloadFiles";
import { Button } from "components/buttons/buttons";

const equipmentApi = new EquipmentApi();
const codebookApi = new CodebookApi();

const { Option } = Select;

const VEHICEL_CODEBOOK_TYPE_ID = "VEHICEL_CODEBOOK_TYPE_ID";

const subjectList = [
  { id: "1", name: "Subject 1" },
  { id: "2", name: "Subject 2" },
];

const Equipment: React.FC<EquipmentProps> = ({
  mode = ModalMode.VIEW,
  onSave,
  isVisible = false,
  onHide,
  equipmentToEdit,
  companyId,
}) => {
  const { t } = useTranslation();

  const [vehiclesData, setVehiclesData] = useState<any>();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);
  const [existingFiles, setExistingFiles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const readOnlyMode = mode === ModalMode.VIEW;
  const updateMode = mode === ModalMode.UPSERT;

  useEffect(() => {
    if (equipmentToEdit) {
      fetchEquipmentDetails();
    } else {
      form.resetFields();
      form.setFieldsValue({
        controlledTemperature: false,
        allowsADR: false
      });
      setFileList([]);
      setExistingFiles([]);
    }
  }, [equipmentToEdit, form, mode]);

  const getTitle =
    mode === ModalMode.UPSERT
      ? t("equipment:edit.title", "Edit Equipment Details")
      : mode === ModalMode.ADD
      ? t("equipment:add.title", "Add Equipment Details")
      : t("equipment:view.title", "Equipment Details");

  const handleOnSave = async () => {
    setIsBtnLoading(true);
    try {
      const values = await form.validateFields();
      const formattedValues = {
        ...values,
        registrationDate: values.registrationDate
          ? values.registrationDate.toISOString()
          : new Date().toISOString(),
        registrationExpiration: values.registrationExpiration
          ? values.registrationExpiration.toISOString()
          : new Date().toISOString(),
      };
      if (equipmentToEdit) {
        await equipmentApi.apiEquipmentsIdPut({
          id: equipmentToEdit?.id as any,
          id2: equipmentToEdit?.id as any,
          ...formattedValues,
          existingFileNames: [...existingFiles]
            .filter((x) => !x.deleted)
            .map((x) => x.fileName),
          toBeDeletedFileNames: [...existingFiles]
            .filter((x) => x.deleted)
            .map((x) => x.fileName),
          equipmentFiles: fileList,
        });
        openNotificationWithIcon(
          "success",
          t(
            "EquipmentModal:equipment-edit-success",
            "Equipment is edited successfuly!"
          )
        );
      } else {
        await equipmentApi.apiEquipmentsPost({
          ...formattedValues,
          companyId,
          equipmentFiles: fileList,
        });
        openNotificationWithIcon(
          "success",
          t(
            "EquipmentModal:equipment-created-success",
            "Equipment is created successfuly!"
          )
        );
      }
      if (onHide && onSave) {
        onHide();
        onSave("" as any);
      }
    } catch (error) {
    } finally {
      setIsBtnLoading(false);
    }
  };

  const fetchVehicles = async () => {
    try {
      const response = (
        await codebookApi.apiCodebookByParentCodeGet({
          code: VEHICEL_CODEBOOK_TYPE_ID,
          pageSize: 1000,
        })
      ).data;
      setVehiclesData(response);
    } catch (error) {}
  };

  const fetchEquipmentDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = (await equipmentApi.apiEquipmentsIdGet({
        id: equipmentToEdit?.id as any,
      })) as any;
      const formattedEquipmentToEdit = {
        ...equipmentToEdit,
        registrationDate: data?.registrationDate
          ? moment(data?.registrationDate)
          : null,
        registrationExpiration: data.registrationExpiration
          ? moment(data.registrationExpiration)
          : null,
        controlledTemperature: data?.controlledTemperature ?? false,
        allowsADR: data?.allowsADR ?? false
      };
      setExistingFiles(
        data?.files?.map((file: any) => ({
          fileName: file.fileName,
          deleted: false,
        })) ?? []
      );
      setFileList(
        readOnlyMode ? data?.files ?? [] : data?.files?.map(base64ToFile) ?? []
      );
      form.setFieldsValue(formattedEquipmentToEdit);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVehicles();
  }, []);

  return (
    <Modal
      title={getTitle}
      visible={isVisible}
      onCancel={onHide}
      width={900}
      type="primary"
      onOk={handleOnSave}
      loading={isBtnLoading}
      hideButtons={mode === ModalMode.VIEW}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <Form form={form} layout="vertical" name="equipmentForm" requiredMark>
          <Row gutter={[15, 15]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="code"
                label={t("equipment:input.code", "Code")}
                rules={[
                  {
                    required: true,
                    message: t("equipment:enter-code", "Please enter code"),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="name"
                label={t("equipment:input.name", "Name")}
                rules={[
                  {
                    required: true,
                    message: t("equipment:enter-name", "Please enter name"),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="plates"
                label={t("equipment:input.plates", "Plates")}
                rules={[
                  {
                    required: true,
                    message: t("equipment:enter-plates", "Please enter plates"),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="productionYear"
                label={t("equipment:input.productionYear", "Production Year")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-production-Year",
                      "Please enter production year"
                    ),
                  },
                ]}
              >
                <Input type="number" disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="registrationDate"
                label={t(
                  "equipment:input.registrationDate",
                  "Registration Date"
                )}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-registration-Date",
                      "Please enter registration date"
                    ),
                  },
                ]}
                getValueProps={(i) => ({ value: moment(i) })}
              >
                <DatePicker
                  disabled={mode === ModalMode.VIEW}
                  style={{ width: "100%", height: 50 }}
                  format="YYYY-MM-DD"
                  defaultValue={moment()}
                  placeholder={t(
                    "equipment:input.registrationDate",
                    "Registration Date"
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="registrationExpiration"
                label={t(
                  "equipment:input.registrationExpiration",
                  "Registration Expiration"
                )}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-registration-Expiration",
                      "Please enter registration expiration"
                    ),
                  },
                ]}
                getValueProps={(i) => ({ value: moment(i) })}
              >
                <DatePicker
                  style={{ width: "100%", height: 50 }}
                  disabled={mode === ModalMode.VIEW}
                  format="YYYY-MM-DD"
                  defaultValue={moment()}
                  placeholder={t(
                    "equipment:input.registrationExpiration",
                    "Registration Expiration"
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="chassisNumber"
                label={t("equipment:input.chassisNumber", "Chassis Number")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-chassis-Number",
                      "Please enter chassis number"
                    ),
                  },
                ]}
              >
                <Input type="number" disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="engineNumber"
                label={t("equipment:input.engineNumber", "Engine Number")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-engine-Number",
                      "Please enter engine number"
                    ),
                  },
                ]}
              >
                <Input type="number" disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="vehicleTypeId"
                label={t("equipment:input.vehicleTypeId", "Vehicle Type")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-vehicle-Type",
                      "Please select vehicle type"
                    ),
                  },
                ]}
              >
                <Select size="large" disabled={mode === ModalMode.VIEW}>
                  {vehiclesData?.items.map((subj: any) => (
                    <Option key={subj.id} value={subj.id}>
                      {subj.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="engineVolume"
                label={t("equipment:input.engineVolume", "Engine Volume")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-engine-Volume",
                      "Please enter engine volume"
                    ),
                  },
                ]}
              >
                <Input type="number" disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="numberOfAxles"
                label={t("equipment:input.axleNumber", "Number of Axles")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-axle-Number",
                      "Please enter axle number"
                    ),
                  },
                ]}
              >
                <Input type="number" disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="emissionStandard"
                label={t(
                  "equipment:input.emissionStandard",
                  "Emission Standard"
                )}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-emission-Standard",
                      "Please enter emission standard"
                    ),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="color"
                label={t("equipment:input.color", "Color")}
                rules={[
                  {
                    required: true,
                    message: t("equipment:enter-color", "Please enter a color"),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="manufacturer"
                label={t("equipment:input.manufacturer", "Manufacturer")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-manufacturer",
                      "Please enter manufacturer"
                    ),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="dryWeight"
                label={t("equipment:input.deadWeight", "Dead weight")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-dead-Weight",
                      "Please enter dead weight"
                    ),
                  },
                ]}
              >
                <Input type="number" disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="enginePower"
                label={t("equipment:input.enginePower", "Engine Power")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "equipment:enter-engine-Power",
                      "Please enter engine power"
                    ),
                  },
                ]}
              >
                <Input type="number" disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="subject"
                label={t("equipment:input.subject", "Subject")}
              >
                <Select size="large" disabled={mode === ModalMode.VIEW}>
                  {subjectList.map((subj) => (
                    <Option key={subj.id} value={subj.name}>
                      {subj.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="controlledTemperature"
                label={t(
                  "equipment:input.controlled-Temperature",
                  "Controlled Temperature"
                )}
                valuePropName="checked"
              >
                <Checkbox disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="allowsADR"
                label={t("equipment:input.allowsADR", "Allows ADR")}
                valuePropName="checked"
              >
                <Checkbox disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item>
                {!readOnlyMode ? (
                  <Dragger
                    fileList={fileList}
                    beforeUpload={(file) => {
                      setFileList((prev: any): any => [...prev, file]);
                      return false;
                    }}
                    onRemove={(file) => {
                      if (updateMode) {
                        setExistingFiles((prev: any) =>
                          prev.map((x: any) => {
                            if (x.fileName.includes(file.name)) {
                              x.deleted = true;
                            }
                            return x;
                          })
                        );
                      }
                      const index = fileList.indexOf(file as never);
                      const newFileList = fileList.slice();
                      newFileList.splice(index, 1);
                      setFileList(newFileList);
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file(s) to this area to upload
                    </p>
                  </Dragger>
                ) : (
                  fileList?.length !== 0 && (
                    <Button
                      type="white"
                      onClick={() => downloadFilesFromBase64(fileList)}
                    >
                      <FeatherIcon icon="download" size="24" />
                      Download files
                    </Button>
                  )
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default Equipment;
