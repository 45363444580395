import { Col, Input, Row, Table } from "antd";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ActivityLogsApi } from "../../api";
/**
 * Hooks and Constants
 */
import { sortDirections } from "constants/constants";
import { useAuthorization } from "hooks/useAuthorization";

/**
 * Styles
 */
import {
  CardToolbox,
  Main,
  ProfilePageheaderStyle,
  TableWrapper,
} from "container/styled";
import { UserTableStyleWrapper } from "../style";

/**
 * Components
 */
import { Button } from "components/buttons/buttons";
import { Cards } from "components/cards/frame/cards-frame";
import { Modal } from "components/modals/antd-modals";
import { PageHeader } from "components/page-headers/page-headers";
import { useTableSorting } from "hooks/useTableSorting";
import { ExportButtonPageHeader } from "components/buttons/export-button/export-button";
import OrdinalNumber from "components/common/OrdinalNumber";

const logsApi = new ActivityLogsApi();

const LogsPage = () => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Authorization
   */
  const { hasPermission } = useAuthorization();

  /**
   * States
   */
  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [logs, setLogs] = useState({
    totalCount: 0,
    pageIndex: 1,
    items: [],
  });
  const { onSorterChange, sorting } = useTableSorting();
  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    fullName: "",
    ip: "",
    logType: 0,
    activity: "",
  });

  const logTypes = [
    { value: 0, label: t("logs.ALL", "ALL") },
    { value: 1, label: t("logs.INFO", "INFO") },
    { value: 2, label: t("logs.ERROR", "ERROR") },
    { value: 3, label: t("logs.EXCEPTION", "EXCEPTION") },
  ];

  const columns = [
    {
      title: t("global.ordinal-number", "No."),
      dataIndex: "ordinalNumber",
      key: "ordinalNumber",
      sorter: false,
    },
    {
      title: t("global.User", "User"),
      dataIndex: "user",
      key: "user",
      sorter: true,
      sortDirections,
    },
    {
      title: t("logs.ip-address", "IP Address"),
      dataIndex: "ip",
      key: "ip",
      sorter: true,
      sortDirections,
    },
    {
      title: t("logs.date-time", "Date and Time"),
      dataIndex: "date",
      key: "date",
      sorter: true,
      sortDirections,
    },
    {
      title: t("logs.log-type", "Log Type"),
      dataIndex: "logType",
      key: "logType",
      sorter: true,
      sortDirections,
    },
    {
      title: t("logs.activity", "Activity"),
      dataIndex: "activity",
      key: "activity",
      sorter: true,
      sortDirections,
    },
    {
      title: t("global.actions", "Actions"),
      dataIndex: "action",
      key: "action",
      width: "70px",
    },
  ];

  /**
   * Generate table data
   */
  const generateTableData = () => {
    return logs?.items?.map((record) => {
      const {
        id,
        ordinalNumber,
        user,
        ip,
        date,
        logType,
        activity,
        description,
      } = record;

      return {
        key: id,
        ordinalNumber: <OrdinalNumber value={ordinalNumber} />,
        user,
        ip,
        date,
        logType: (
          <span style={{ color: `${logType === "INFO" ? "green" : "red"}` }}>
            {logType}
          </span>
        ),
        // activity: hasPermission("logs:view-details") && (
        activity: hasPermission("users:list") && (
          <span style={{ marginRight: "5px" }}>{activity}</span>
        ),
        action: (
          <div
            key={id}
            className="table-actions"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* {hasPermission("logs:view-details") && ( */}
            {hasPermission("users:list") && (
              <>
                <Button
                  className="btn-icon"
                  type="primary"
                  to="#"
                  shape="circle"
                >
                  <Link
                    onClick={() => {
                      setDetailsModalVisible(true);
                      setSelectedLog(record);
                    }}
                    to="#"
                  >
                    <FeatherIcon icon="eye" size={16} />
                  </Link>
                </Button>
                <Button
                  className="btn-icon"
                  type="primary"
                  to="#"
                  shape="circle"
                >
                  <Link
                    onClick={() => navigator.clipboard.writeText(description)}
                    to="#"
                  >
                    <FeatherIcon icon="copy" size={16} />
                  </Link>
                </Button>
              </>
            )}
          </div>
        ),
      };
    });
  };

  /**
   * Get logs
   */
  const getLogs = async () => {
    const { data } = await logsApi.activitiesGet({ ...query, ...sorting });
    setLogs(data);
    setLoading(false);
  };

  /**
   * Use Effect
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(true);
      getLogs();
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [query, sorting]);

  /**
   * Tables Event
   */
  const handlePaginationChange = (pageNumber) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber }));
  };

  const handleShowSizeChange = (pageNumber, pageSize) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  return (
    <>
      <CardToolbox>
        <ProfilePageheaderStyle>
          <PageHeader
            ghost
            title={t("logs.title", "Logs")}
            buttons={[
              <ExportButtonPageHeader
                key="1"
                title={t("logs.title", "Logs")}
                data={generateTableData()}
                columns={[
                  "ordinalNumber",
                  "user",
                  "ip",
                  "date",
                  "logType",
                  "activity",
                ]}
              />,
            ]}
          />
        </ProfilePageheaderStyle>
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless s>
              <Row
                gutter={[15, 15]}
                justify="spaces-around"
                style={{ zIndex: 999 }}
              >
                <Col md={6}>
                  <Input
                    style={{ height: "38px" }}
                    placeholder={t("logs.by-user", "User (Full Name)")}
                    onChange={(e) => {
                      setQuery({
                        ...query,
                        fullName: e.target.value,
                        pageNumber: 1,
                      });
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    style={{ height: "38px" }}
                    placeholder={t("logs.ip-address", "IP Address")}
                    onChange={(e) => {
                      setQuery({ ...query, ip: e.target.value, pageNumber: 1 });
                    }}
                  />
                </Col>
                <Col md={6} style={{ zIndex: 999 }}>
                  <Select
                    placeholder={t("logs.log-type", "Log Type")}
                    styles={[{ zIndex: 999 }]}
                    options={logTypes}
                    onChange={(selectedOption) => {
                      setQuery({
                        ...query,
                        logType: selectedOption.value,
                        pageNumber: 1,
                      });
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    style={{ height: "38px" }}
                    placeholder={t("logs.activity", "Activity")}
                    onChange={(e) => {
                      setQuery({
                        ...query,
                        activity: e.target.value,
                        pageNumber: 1,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Cards>
          </Col>
          <Col md={24}>
            <UserTableStyleWrapper>
              <Cards headless>
                <TableWrapper>
                  <Table
                    loading={isLoading}
                    dataSource={generateTableData()}
                    columns={columns}
                    showSorterTooltip={false}
                    scroll={{ x: "max-content" }}
                    pagination={{
                      position: ["bottomCenter"],
                      pageSize: query.pageSize,
                      total: logs.totalCount,
                      current: logs.pageIndex,
                      showSizeChanger: true,
                      pageSizeOptions: [10, 50, 100, 1000],
                      showTotal: (total) =>
                        `${t("logs.total", "Total")} ${total} ${t(
                          "logs.log",
                          "Log(s)"
                        )}`,
                      onChange: handlePaginationChange,
                      onShowSizeChange: handleShowSizeChange,
                    }}
                    onChange={(_, __, sorter) => onSorterChange(sorter)}
                  />
                </TableWrapper>
              </Cards>
            </UserTableStyleWrapper>
          </Col>
        </Row>
        <Modal
          type="primary"
          title={selectedLog?.activity}
          visible={isDetailsModalVisible}
          footer={null}
          onCancel={() => setDetailsModalVisible(false)}
          width="80%"
        >
          <span
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: selectedLog?.description }}
          ></span>
        </Modal>
      </Main>
    </>
  );
};

export default LogsPage;
