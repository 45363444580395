import { CodebookTypeApi } from "api/api";
import { PredefinedRoles } from "constants/constants";
import { getRole } from "utility/decode-jwt";
import { getClaims } from "utility/getClaims";
import {
  isAdminUser,
  isCompanyUser,
  isSimpleUser,
} from "utility/isCompanyUser";

import { toDashedKey } from "utility/utility";
const codebookApi = new CodebookTypeApi();

async function getDynamicCodebookMenuItems(t: any) {
  const dynamicCodebooks =
    (await codebookApi.apiCodebookTypesGet())?.data ?? []; // Fetch from your API
  return dynamicCodebooks.map((codebook) => ({
    key: toDashedKey(codebook.name), // Use label to generate the key
    label: t(
      `side-bar:configurations.codebook-types.${toDashedKey(codebook.name)}`,
      codebook.name
    ),
    path: `/codebook-types/${codebook.id}`,
    claimName: `${toDashedKey(codebook.name)}`,
  }));
}

export const menuItemsList = async (t: any) => {
  const dynamicCodebookSubMenuItems = await getDynamicCodebookMenuItems(t);
  const roles = getRole();
  const isAdminRole = Array.isArray(roles)
    ? roles.includes(PredefinedRoles.SUPER_ADMINISTRATOR)
    : roles === PredefinedRoles.SUPER_ADMINISTRATOR;

  const isFreightForwarderRole = Array.isArray(roles)
    ? roles.includes(PredefinedRoles.FREIGHT_FORWARDER)
    : roles === PredefinedRoles.FREIGHT_FORWARDER;
  console.log("isSimpleUser", isSimpleUser());
  console.log("isAdminRole", isAdminRole);
  console.log("roles", roles);
  const dashboard =
    // (isCompanyUser() || isSimpleUser()) && !isAdminUser()
    isSimpleUser()
      ? {
          dashboard: {
            key: "dashboard",
            label: t("side-bar:dashboard", "Dashboard"),
            icon: "home",
            path: "/dashboard/company",
          },
        }
      : null;

  const administrationSubMenuItems = [
    {
      key: "users",
      label: t(
        isAdminRole
          ? "side-bar:administration.users"
          : "side-bar:administration.employees",
        isAdminRole ? "Users" : "Employees"
      ),
      path: "/users",
      claimName: "users",
    },
    {
      key: "user-groups",
      label: t(
        isAdminRole
          ? "side-bar:administration.user-groups"
          : "side-bar:administration.permissions",
        isAdminRole ? "User Groups" : "Permissions"
      ),
      path: "/user-groups",
      claimName: "user-groups",
    },
  ];

  const shipmentsSubMenuItems = [
    {
      key: "workbook",
      label: t("side-bar:workbook", "Workbook"),
      path: "/workbook",
      // claimName: "deals",
    },
  ];

  return {
    ...dashboard,
    ...(isSimpleUser()
      ? {
          clients: {
            key: "clients",
            label: t("side-bar:clients", "Clients"),
            icon: "user",
            path: "/clients",
            // claimName: "clients",
          },
          transporters: {
            key: "transporters",
            label: t("side-bar:transporters", "Transporters"),
            icon: "truck",
            path: "/transporters",
            // claimName: "transporters",
          },
        }
      : {}),
    freightForwarders: {
      key: "freight-forwarders",
      label: t("side-bar:freight-forwarders", "Freight Forwarders"),
      icon: "users",
      path: "/freight-forwarders",
      claimName: "freight-forwarders",
    },
    ...(isSimpleUser()
      ? {
          deals: {
            key: "shipments",
            label: t("side-bar:shipments", "Shipments"),
            icon: "file",
            subMenuItems: shipmentsSubMenuItems,
          },
          newsFeed: {
            key: "newsfeed",
            label: t("side-bar:newsfeed", "NewsFeed"),
            icon: "file",
            path: "/newsfeed",
          }
        }
      : {}),
    customOffices: {
      key: "custom-office",
      label: t("side-bar:custoom-offices", "Custom Offices"),
      icon: "file",
      path: "/custom-offices",
      // claimName: "custom-offices",
    },
    administration: {
      key: "administration",
      label: t("side-bar:administration", "Administration"),
      icon: "users",
      subMenuItems: administrationSubMenuItems,
    },
    configurations: {
      key: "configurations",
      label: t("side-bar:configurations", "Configurations"),
      icon: "settings",
      subMenuItems: [
        {
          key: "email",
          label: t("side-bar:configurations.email", "Email"),
          path: "/email",
          // claimName: "email-options",
        },
        {
          key: "translations",
          label: t("side-bar:configurations.translations", "Translations"),
          path: "/translations",
          // claimName: "translations",
        },
        {
          key: "languages",
          label: t("side-bar:configurations.languages", "Languages"),
          path: "/languages",
          // claimName: "languages",
        },
        {
          key: "codebook-types",
          label: t("side-bar:configurations.codebook-types", "Codebook Types"),
          path: "/codebook-types",
          // claimName: "codebook-types",
        },
        {
          key: "countries",
          label: t("side-bar:configurations.countries", "Countries"),
          path: "/countries",
          // claimName: "codebook-types",
        },
      ],
    },
    codebooks: {
      key: "codebooks",
      label: t("side-bar:codebooks", "Codebooks"),
      icon: "file-text",
      subMenuItems: dynamicCodebookSubMenuItems,
    },
    logs: {
      key: "logs",
      label: t("side-bar:logs", "Logs"),
      path: "/logs",
      icon: "rewind",
      // claimName: "logs",
    },
  };
};

export const getCurrentUserModules = (): string[] => {
  const claims = getClaims();
  const modules = claims.map((x) => x?.split(":")[0]);
  return [...new Set<string>(modules)];
};
