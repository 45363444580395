// src/components/EquipmentColumns.tsx
import React from "react";
import { ColumnsType } from "antd/es/table";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import { Button } from "components/buttons/buttons";
import { Popconfirm } from "antd";
import { hasPermission } from "utility/accessibility/hasPermission";
import { ModalMode } from "hooks/useModalState";

export interface EquipmentType {
  id: string;
  code: string;
  name: string;
  plates: string;
  productionYear: number;
  registrationDate: string;
  registrationExpiration: string;
  chassisNumber: string;
  subject: string;
  engineNumber: string;
  vehicleType: string;
  engineVolume: string;
  numberOfAxles: number;
  emissionStandard: string;
  color: string;
  manufacturer: string;
  dryWeight: string;
  enginePower: string;
  controlledTemperature: boolean;
  allowsADR: boolean;
}

export interface EquipmentProps {
  mode?: ModalMode;
  onSave?: (equipment: EquipmentType) => void;
  onHide?: () => void;
  isVisible?: boolean;
  equipmentToEdit?: EquipmentType | null;
  companyId: string;
}

interface ActionHandlers {
  handleViewClick: (record: EquipmentType) => void;
  handleEditClick: (record: EquipmentType) => void;
  handleDeleteClick: (record: EquipmentType) => void;
}

export const useEquipmentColumns = ({
  handleViewClick,
  handleEditClick,
  handleDeleteClick,
}: ActionHandlers): ColumnsType<EquipmentType> => {
  const { t } = useTranslation();

  return [
    {
      title: t("equipment:input.code", "Code"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("equipment:input.name", "Name"),
      dataIndex: "name",
      key: "name",
      sorter: false,
    },
    {
      title: t("equipment:input.plates", "Plates"),
      dataIndex: "plates",
      key: "plates",
      sorter: false,
    },
    {
      title: t("equipment:input.productionYear", "Production Year"),
      dataIndex: "productionYear",
      key: "productionYear",
      sorter: false,
    },
    {
      title: t("equipment:input.registrationDate", "Registration Date"),
      dataIndex: "registrationDate",
      key: "registrationDate",
      sorter: false,
      render: (text) => (text ? new Date(text).toLocaleDateString() : ""),
    },
    {
      title: t(
        "equipment:input.registrationExpiration",
        "Registration Expiration"
      ),
      dataIndex: "registrationExpiration",
      key: "registrationExpiration",
      sorter: false,
      render: (text) => (text ? new Date(text).toLocaleDateString() : ""),
    },
    {
      title: t("equipment:input.chassisNumber", "Chassis Number"),
      dataIndex: "chassisNumber",
      key: "chassisNumber",
      sorter: false,
    },
    {
      title: t("equipment:input.subject", "Subject"),
      dataIndex: "subject",
      key: "subject",
      sorter: false,
    },
    {
      title: t("equipment:input.engineNumber", "Engine Number"),
      dataIndex: "engineNumber",
      key: "engineNumber",
      sorter: false,
    },
    {
      title: t("equipment:input.vehicleType", "Vehicle Type"),
      dataIndex: "vehicleType",
      key: "vehicleType",
      sorter: false,
    },
    {
      title: t("equipment:input.engineVolume", "Engine Volume"),
      dataIndex: "engineVolume",
      key: "engineVolume",
      sorter: false,
    },
    {
      title: t("equipment:input.axleNumber", "Number of Axles"),
      dataIndex: "numberOfAxles",
      key: "numberOfAxles",
      sorter: false,
    },
    {
      title: t("equipment:input.emissionStandard", "Emission Standard"),
      dataIndex: "emissionStandard",
      key: "emissionStandard",
      sorter: false,
    },
    {
      title: t("equipment:input.color", "Color"),
      dataIndex: "color",
      key: "color",
      sorter: false,
    },
    {
      title: t("equipment:input.manufacturer", "Manufacturer"),
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: false,
    },
    {
      title: t("equipment:input.dryWeight", "Dry Weight"),
      dataIndex: "dryWeight",
      key: "dryWeight",
      sorter: false,
    },
    {
      title: t("equipment:input.enginePower", "Engine Power"),
      dataIndex: "enginePower",
      key: "enginePower",
    },
    {
      title: t("destinations.table.action", "Action"),
      key: "action",
      render: (_, record) => (
        <div className="table-actions">
          <Button
            className="btn-icon"
            type="info"
            shape="circle"
            onClick={() => handleViewClick(record)}
          >
            <FeatherIcon icon="eye" size={25} />
          </Button>
          {hasPermission("users:edit") ? (
            <Button
              className="btn-icon"
              type="info"
              shape="circle"
              onClick={() => handleEditClick(record)}
            >
              <FeatherIcon icon="edit" size={25} />
            </Button>
          ) : null}
          {hasPermission("users:delete") ? (
            <Popconfirm
              title={t(
                "equipments:alert.delete-confirm",
                "This step is irreversible, are you sure you want to delete {{dynamicValue}}?",
                { dynamicValue: `${record.name}` }
              )}
              onConfirm={() => handleDeleteClick(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="btn-icon" type="info" shape="circle">
                <FeatherIcon icon="trash" size={25} />
              </Button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];
};
