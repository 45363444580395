import { Col, Popconfirm, Row, Table } from "antd";
import {
  CodebookApi,
  CodebookApiApiCodebookByTypeGetRequest,
  CodebookTypeApi,
} from "api/api";
import { CodebookDto, CodebookDtoPaginatedList } from "api/models";
import { Button } from "components/buttons/buttons";
import { Cards } from "components/cards/frame/cards-frame";
import { PageHeader } from "components/page-headers/page-headers";
import {
  CardToolbox,
  Main,
  ProfilePageHeaderStyle,
  ProfileTableStyleWrapper,
  TableWrapper,
} from "container/styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { hasPermission } from "utility/accessibility/hasPermission";
//@ts-ignore
import FeatherIcon from "feather-icons-react";
import { CreateCommonCodebookModal } from "pages/codebooks/CodebookCommonPage/CreateCommonCodebookModal";
import {
  codebookClaimNames,
  getCodebookTextsByType,
} from "pages/codebooks/CodebookCommonPage/data";
import openNotificationWithIcon from "utility/notification";
import { ColumnsType } from "antd/lib/table";
import { sortDirections } from "constants/constants";
import { useTableSorting } from "hooks/useTableSorting";
import { ExportButtonPageHeader } from "components/buttons/export-button/export-button";
import { toDashedKey } from "utility/utility";

const codebookApi = new CodebookApi();
const codebookTypeApi = new CodebookTypeApi();

type StateType = {
  addModalVisible?: boolean;
  itemForEditModal?: CodebookDto | null;
};
export const CodebookCommonPage = () => {
  const { t } = useTranslation();
  const { id: codebookTypeId } = useParams();
  const [translatedTexts, setTranslatedTexts] = useState<any>();
  const { sorting, onSorterChange } = useTableSorting();
  const [request, setRequest] =
    useState<CodebookApiApiCodebookByTypeGetRequest>({
      search: "",
      pageNumber: 1,
      pageSize: 10,
    });
  const [codebookData, setCodebookData] = useState<CodebookDtoPaginatedList>({
    items: [],
  });

  const [codebookItemsLoading, setCodebookItemsLoading] = useState(false);
  const [modalsState, setModalsState] = useState<StateType>({
    addModalVisible: false,
    itemForEditModal: null,
  });

  useEffect(() => {
    Promise.all([
      fetchCodeBookTypeDetails(codebookTypeId),
      fetchCodebookItems(codebookTypeId),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codebookTypeId, sorting, request]);

  const fetchCodeBookTypeDetails = async (id: any) => {
    try {
      setCodebookItemsLoading(true);

      const response = await codebookTypeApi.apiCodebookTypesIdGet({
        id,
      });

      setTranslatedTexts(getCodebookTextsByType(t, response.data.name));
    } catch (error) {
    } finally {
      setCodebookItemsLoading(false);
    }
  };

  const fetchCodebookItems = async (code: any) => {
    try {
      setCodebookItemsLoading(true);

      const response = await codebookApi.apiCodebookByTypeGet({
        codebookTypeId,
        ...request,
        ...sorting,
      });

      setCodebookData(response.data);
    } catch (error) {
    } finally {
      setCodebookItemsLoading(false);
    }
  };

  const handleAddClick = () => {
    setModalsState({
      addModalVisible: true,
    });
  };

  const handleEditClick = (item: CodebookDto) => {
    setModalsState({
      itemForEditModal: item,
    });
  };
  const handleSuccessAddOrEdit = () => {
    closeModal();
    fetchCodebookItems(codebookTypeId);
  };
  const closeModal = () => {
    setModalsState({
      addModalVisible: false,
      itemForEditModal: null,
    });
  };

  const handleDeleteConfirm = async (id: string | undefined) => {
    try {
      if (!id) return;
      await codebookApi.apiCodebookIdDelete({ id });
      openNotificationWithIcon(
        "success",
        t(
          `${translatedTexts?.label}:success-deleted"`,
          `${translatedTexts?.label} deleted successfully!`
        )
      );
      fetchCodebookItems(codebookTypeId);
    } catch (err) {}
  };

  const handlePaginationChange = (pageNumber: number) => {
    setRequest((prevQuery) => ({ ...prevQuery, pageNumber }));
  };

  const onShowSizeChange = (pageNumber: number, pageSize: number) => {
    setRequest((prevQuery) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  const columns: ColumnsType<any> = [
    {
      title: t("global.name", "Name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortDirections,
    },
    {
      title: t("global.code", "Code"),
      dataIndex: "code",
      key: "code",
      sorter: true,
      sortDirections,
    },
    {
      title: t("global.actions", "Actions"),
      dataIndex: "action",
      key: "action",
      width: "90px",
    },
  ];

  const tableData = (codebookData?.items ?? []).map((item) => {
    const { id, name, code } = item;

    return {
      key: id,
      name,
      code,
      action: (
        <div
          key={id}
          className="table-actions"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {hasPermission(`users:edit`) && (
            <Button className="btn-icon" type="primary" to="#" shape="circle">
              <Link onClick={() => handleEditClick(item)} to="#">
                <FeatherIcon icon="edit" size={16} />
              </Link>
            </Button>
          )}
          {hasPermission(`users:delete`) ? (
            <>
              <Popconfirm
                title={translatedTexts?.deleteConfirm}
                onConfirm={() => handleDeleteConfirm(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className="btn-icon"
                  type="danger"
                  to="#"
                  shape="circle"
                >
                  <FeatherIcon icon="trash-2" size={16} />
                </Button>
              </Popconfirm>
            </>
          ) : null}
        </div>
      ),
    };
  });

  return (
    <>
      <CardToolbox>
        <ProfilePageHeaderStyle>
          <PageHeader
            ghost
            title={translatedTexts?.title}
            buttons={[
              <ExportButtonPageHeader
                key="1"
                title={translatedTexts?.title}
                data={tableData}
                columns={["name"]}
              />,
              hasPermission(`users:add`) && (
                <Button
                  onClick={handleAddClick}
                  className="btn-add_new"
                  size="default"
                  type="primary"
                  key="add-codebook"
                >
                  {translatedTexts?.add}
                </Button>
              ),
            ]}
          />
        </ProfilePageHeaderStyle>
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <ProfileTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    <Table
                      loading={codebookItemsLoading}
                      dataSource={tableData}
                      columns={columns}
                      showSorterTooltip={false}
                      rowKey="key"
                      pagination={{
                        current: codebookData?.pageIndex,
                        total: codebookData?.totalCount,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 50, 100, 1000],
                        onChange: handlePaginationChange,
                        onShowSizeChange: onShowSizeChange,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                      onChange={(_, __, sorter) => onSorterChange(sorter)}
                    />
                  </TableWrapper>
                </div>
              </ProfileTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
      </Main>

      <CreateCommonCodebookModal
        isVisible={Boolean(modalsState.addModalVisible)}
        codebookType={translatedTexts?.title}
        codebookTypeId={codebookTypeId}
        onHide={closeModal}
        onSuccess={handleSuccessAddOrEdit}
      />
      <CreateCommonCodebookModal
        isVisible={Boolean(modalsState.itemForEditModal)}
        codebookType={translatedTexts?.title}
        codebookTypeId={codebookTypeId}
        onHide={closeModal}
        onSuccess={handleSuccessAddOrEdit}
        codebookToEdit={modalsState.itemForEditModal}
      />
    </>
  );
};
