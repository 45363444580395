import { Table } from "antd";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";

import { DriversApi } from "api/api";

import { Button } from "components/buttons/buttons";
import { Cards } from "components/cards/frame/cards-frame";
import { useDriversFetch } from "components/drivers/hooks/useDriversFetch";
import { DriverProps, useDriverColumns } from "components/drivers/driverDataTableColumns";

import { TableWrapper } from "container/styled";

import openNotificationWithIcon from "utility/notification";
import { hasPermission } from "utility/accessibility/hasPermission";

import Driver from "components/drivers/driver";
import { useModalState } from "hooks/useModalState";

const driversApi = new DriversApi();

const Drivers = ({companyId} : DriverProps) => {
  const { t } = useTranslation();

  const canAddDriver = hasPermission("users:add");

  const { data, isLoading, fetchDrivers, setQuery } = useDriversFetch({companyId});

  const {
    modalState,
    closeModal,
    handleAddClick,
    handleViewClick,
    handleEditClick,
  } = useModalState(fetchDrivers);

  const columns = useDriverColumns({
    handleViewClick,
    handleEditClick,
    handleDeleteClick: async (item: any) => {
      try {
        await driversApi.apiDriversIdDelete({ id: item.id });
        fetchDrivers();
        openNotificationWithIcon(
          "success",
          t(
            "driverModal:driver-deleted-success",
            "Driver deleted successfully!"
          )
        );
      } catch (error) {}
    },
  });

  return (
    <div style={{ width: "100%" }}>
      <Cards
        title={t("drivers:title", "Drivers")}
        size="large"
        isbutton={
          canAddDriver && (
            <Button
              onClick={handleAddClick}
              className="btn-add_new"
              size="default"
              type="white"
              key="add-codebook"
            >
              <FeatherIcon icon="plus" size={25} />
              {t("drivers:button.add", "Add New")}
            </Button>
          )
        }
      >
        <TableWrapper className="table-responsive">
          <Table
            loading={isLoading}
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            pagination={{
              current: data?.pageIndex,
              total: data?.totalCount,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100", "1000"],
              onChange: (pageNumber, pageSize) =>
                setQuery((prev) => ({ ...prev, pageNumber, pageSize })),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </Cards>
      <Driver
        isVisible={modalState.addModalVisible}
        onHide={closeModal}
        mode={modalState.mode as any}
        driverToEdit={modalState.itemForEditModal}
        onSave={fetchDrivers}
        companyId={companyId}
      />
    </div>
  );
};

export default Drivers;
